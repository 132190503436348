import { Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

const CenterLoading = ({
  params,
  open,
  showSuggestions,
  suggestionList = [],
  suggestionInterval = 3,
}) => {
  const [currSuggestion, setCurrSuggestion] = useState(0);

  useEffect(() => {
    let interval;
    if (showSuggestions) {
      interval = setInterval(() => {
        setCurrSuggestion((prev) => {
          if (prev + 1 < suggestionList.length) return prev + 1;

          return 0;
        });
      }, suggestionInterval * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Modal open={open}>
      <Box
        className="center-loading"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: showSuggestions ? "350px" : "auto",
          height: showSuggestions ? "150px" : "auto",
          maxWidth: showSuggestions ? "80%" : "auto",
          bgcolor: "#0e1b1d",
          border: "2px solid #000",
          boxShadow: 24,
          borderRadius: "10px",
          p: showSuggestions ? 0 : 4,
          textAlign: "center",
        }}
      >
        <div
          style={{
            position: showSuggestions ? "absolute" : "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
        <div className="center-content" style={{}}>
          {showSuggestions && (
            <span
              style={{
                display: "block",
                color: "white",
                marginTop: "14px",
              }}
            >
              {suggestionList[currSuggestion]}
            </span>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default CenterLoading;
