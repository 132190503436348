import React from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setUserData } from "../../../redux/action/user";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Box, InputLabel } from "@mui/material";
import { Chart } from "react-google-charts";

const HistoryBody = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const review = async (selectedSurvey) => {
    if (selectedSurvey.s_IsSurveyCompleted === 1) {
      localStorage.setItem("progressDone", true);
    } else {
      localStorage.removeItem("progressDone");
      localStorage.removeItem("detailDone");
    }

     if (
      selectedSurvey.Height &&
      selectedSurvey.Weight &&
      selectedSurvey.ZipCode &&
      selectedSurvey.Age &&
      selectedSurvey.Gender
    ) {
      if(selectedSurvey.Score === 0){
        localStorage.removeItem("detailDone");
      }
      else{
        localStorage.setItem("detailDone", true);
      }
    } else {
      localStorage.removeItem("detailDone");
    }

    if (localStorage.getItem("progressDone") === null) {
      navigate(`/progress`);
    } else if (localStorage.getItem("detailDone") === null) {
      navigate("/profiledetail");
    } else {
      navigate(`/result`);
    }
    localStorage.setItem("assesmentKey", selectedSurvey.SurveyId);
    dispatch(setUserData({ surveyId: selectedSurvey.SurveyId }));
  };

  const chartData = [["Date and Time", "Score", { role: 'annotation' }]];
  props.user.surveyIdList.forEach((survey, index) => {
    if(props.user.surveyIdList.length > 10){
      if(index >= props.user.surveyIdList.length - 10){
        chartData.push([`${survey.CreationDate}  ${survey.CreationTime}`, survey.Score, survey.Score]);
      }
    }
    else{
      chartData.push([`${survey.CreationDate}  ${survey.CreationTime}`, survey.Score, survey.Score]);
    }
  });

  return (
    <div style={{ padding: "30px 50px 30px 50px" }}>
      {props.user.surveyIdList && props.user.surveyIdList.length > 0 && (
        <div style={{ paddingBottom : "30px"}}>
          <Box  sx={{...ChartWrap}}>
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="LineChart"
            loader={<div>Loading Assessment Data</div>}
            data={chartData}
            options={{
              backgroundColor: { fill:'transparent' },
              colors: ['#FFA500'],
              title: "My Progress",
              hAxis: {
                title: "Date and Time",
              },
              vAxis: {
                title: "Score",
              },
              titleTextStyle: {
                color: '#e29c2e',
              },
              isStacked: true,
            }}
            rootProps={{ "data-testid": "1" }}
            backgroundColor="transparent"
            
          />
          </Box>
          
        </div>
      )}
      <TableContainer sx={{
        border: "2px solid #283436",
        borderRadius: "15px",
        backgroundColor: "#0e1b1d",
        p:1,
        width:"auto",
        table:{
          tbody:{
            tr:{
              td:{
                "&:last-child":{
                  label:{
                    "&:hover":{
                      color:"#e29c2e !important",
                    }
                  }
                 
                }
              }
            }
          }
        }
      }}>
        <Table sx={{ backgroundColor: "transparent" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}>Assesment</TableCell>
              <TableCell align="right" style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}>Assesment Date</TableCell>
              <TableCell align="right" style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}>Assesment Time</TableCell>
              <TableCell align="right" style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}>Vitality Score</TableCell>
              <TableCell align="right" style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.user.surveyIdList &&
              props.user.surveyIdList.length > 0 &&
              props.user.surveyIdList.map((row) => (
                <TableRow
                  key={row.SurveyId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" style={{ color: "whitesmoke" }}>
                    {`Assessement - ${row.SurveyId + 1}`}
                  </TableCell>
                  <TableCell align="right" style={{ color: "whitesmoke" }}>{row.CreationDate}</TableCell>
                  <TableCell align="right" style={{ color: "whitesmoke" }}>{row.CreationTime}</TableCell>
                  <TableCell align="right" style={{ color: "whitesmoke" }}>
                    {row.Score === 0 ? "In Progress" : row.Score}
                  </TableCell>
                  <TableCell align="right" style={{ color: "whitesmoke" }}>
                    <InputLabel
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        paddingRight: "20px",
                      }}
                      onClick={() => review(row)}
                    >
                      {`${row.Score === 0 ? "Continue" : "Review"}`}
                    </InputLabel>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

HistoryBody.propTypes = {};
HistoryBody.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(HistoryBody);

const ChartWrap ={
  border: "2px solid #283436",
  borderRadius: "15px",
  backgroundColor: "#0e1b1d",
  p:1,
    svg:{
     "&>g":{
        rect:{
          fill: "whiteSmoke",
        },
        text:{
          fill:"#fff",
          fontSize: "0.875rem",
          "&:hover":{
            fill:"rgb(235 180 93)",
          }
        },
        "&:nth-of-type(1)":{
          text:{
              fill: "rgb(235 180 93)" ,
              fontWeight: 800,
              fontSize: "17px",
          }
        },
        "&:last-child":{
          text:{
            fill: "#000" ,
            
          }
        }
      }
    }

}
