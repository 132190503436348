import React from 'react';
import './profileDetail.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user'

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ProfileSection from '../../component/profileSection';
import Footer from '../../component/footer';
import Header from '../../component/header';
import bgImage from '../../assets/images/bg-top-repeat.png'

const ProfileDetail = (props) => {

    return (
        <><Header />
            <div className='ab-bg-image'>
                <img src={bgImage} alt={bgImage} ></img>
            </div>
            <div className="login-container">
                <div className="flex-center">
                    <div className='out-box-wrap'>
                        <Card sx={{ display: 'flex', borderRadius: '15px', backgroundColor: 'transparent' }}>
                            <Box sx={{ width: '100%' }}>
                                <ProfileSection />
                            </Box>
                        </Card>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

ProfileDetail.propTypes = {};
ProfileDetail.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(ProfileDetail);
