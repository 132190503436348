import { TextField } from '@mui/material';
import React from 'react';
import './textbox.scss';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#6b7374',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FAAA2F',
        },
    },
});


const TextBox = (props) => {
    return (
        <Tooltip
            title={props.tooltipText}
            arrow={true}
            placement={'top'}
            disableHoverListener={props.tooltipText ? false : true}
            disableFocusListener={props.tooltipText ? false : true}>

            <CssTextField
                color={'error'}
                type={props.type ? props.type : 'text'}
                onChange={(e) => props.valueChange && props.valueChange(e.target.value)}
                placeholder={props.placeholder}
                value={props.value}
                InputProps={{
                    startAdornment: props.prefix ? <img src={props.prefix} alt={props.placeholder} /> : null
                }}
                sx={{
                    '& fieldset': { borderRadius: '10px', border: '1px solid #364242' },
                    '& input': { paddingLeft: (theme) => theme.spacing(1), color: 'white', borderRadius: '10px', backgroundColor: '#041214' },
                    '& input:focus': { border: '1px solid #fff' },
                }}
            />
        </Tooltip>
    );
};

TextBox.propTypes = {};
TextBox.defaultProps = {};

export default TextBox;
