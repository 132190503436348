import { ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import './countryDropDown.scss';
import { makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import countryList from '../../assets/data/countryCode.json'
import parse from 'html-react-parser';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiSvgIcon-root": {
            color: "white"
        }
    },
}));


const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#6b7374',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FAAA2F',
        },
    },
});

const CountryDropDown = (props) => {
    const classes = useStyles();

    return (
        <Tooltip
            title={props.tooltipText}
            arrow={true}
            placement={'top'}
            disableHoverListener={props.tooltipText ? false : true}
            disableFocusListener={props.tooltipText ? false : true}>

            <CssTextField
                type={props.type ? props.type : 'text'}
                onChange={(e) => props.valueChange && props.valueChange(e.target.value)}
                placeholder={props.placeholder}
                value={props.value}
                id="demo"
                inputProps={{
                    onWheel: (e) => {
                        e.target.blur()
                    },
                }}
                InputProps={{
                    startAdornment: <Select
                        id="countryOption"
                        sx={{
                            color: 'white',
                            '& fieldset': { borderRadius: '10px', border: 'none' },
                        }}
                        className={`${classes.root} country-option`}
                        variant="outlined"
                        defaultValue={props.dropDownDefaultValue}
                        onChange={(e) => props.countryCodeChange && props.countryCodeChange(e.target.value)}
                    >
                        {
                            countryList.map((obj, index) => {
                                return <MenuItem value={obj.dialCode} style={{ display: 'flex' }}>
                                    <ListItemIcon className='svg-holder' style={{ maxWidth: '36px' }} >
                                        {parse(obj.flag)}
                                    </ListItemIcon>
                                    <ListItemText>{obj.dialCode}</ListItemText>
                                </MenuItem>
                            })
                        }
                    </Select>
                }}
                sx={{
                    '& input': { paddingLeft: (theme) => theme.spacing(1), color: 'white', borderRadius: '10px', backgroundColor: '#041214' },
                    '& fieldset': { borderRadius: '10px', border: '1px solid #364242', },
                }}
            />
        </Tooltip>
    );
};

CountryDropDown.propTypes = {};
CountryDropDown.defaultProps = {};

export default CountryDropDown;
