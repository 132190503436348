import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import "./resultHeader.scss";
import headerImage from "../../../assets/images/header.png";
import bgImage from "../../../assets/images/result-bg-header.png";
import { getSurveyList } from "../../../redux/action/user";
import { InputLabel } from "@mui/material";

const ResultHeader = (props) => {
  const navigate = useNavigate();

  function signout() {
    localStorage.clear("userKey");
    localStorage.clear("_grecaptcha");
    localStorage.removeItem("review");
    sessionStorage.clear("_grecaptcha");
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate("/signin");
    alert(
      "Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment"
    );
    window.location.reload();
  }

  return (
    <div className="result-header-section">
      <div className="ab-bg-image">
        <img src={bgImage} alt={bgImage}></img>
      </div>
      <div className="result-header-section__fixed bg-transparent border-none">
        <div className="gridContainer">
          <div className="inner-result-header__fixed">
            <div>
              <img src={headerImage} alt={headerImage}></img>
            </div>
            <div className="cus-label result-header-section__right-side">
              <div>
                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
              </div>
                {
                  props.user?.surveyIdList && props.user?.surveyIdList.length > 0 &&
                  <div className="my-data-label">
                    <InputLabel
                        style={{ cursor: "pointer", paddingRight: "20px"}}
                        onClick={() => navigate("/history")}
                      >
                        My Data
                    </InputLabel>
                  </div>
                }
              <div className="progress-header-section__right-side--logout">
                <InputLabel
                  style={{ cursor: "pointer", paddingRight: "30px"}}
                  onClick={() => signout()}
                >
                  Logout
                </InputLabel>
              </div>
              <div className="result-header-section__right-side--userdetail">
                <div>Welcome</div>
                <div>{props.user.firstName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResultHeader.propTypes = {};
ResultHeader.defaultProps = {};

const actionCreators = {
  getSurveyList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ResultHeader);
