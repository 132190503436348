import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import Signin from "../pages/login/signin"
import ProfileDetail from "../pages/profileDetail";
import Progress from "../pages/progress";
import Result from "../pages/result";
import ScrollToTop from "./ScrollToTop";
import History from "../pages/history";

const RouteContainer = (props) => {

  useEffect(() => {
    // console.log("this is demo DAta :: ", process.env)
  }, []);

  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/" element={<ScrollToTop />}>
        <Route path="/" index element={<Login />} />        
        <Route path="profiledetail" element={<ProfileDetail />} />
        <Route path="progress" element={<Progress key={props.user.surveyId} />} />
        <Route path="result" element={<Result key={props.user.surveyId} />} />
        <Route path="history" element={<History />} />
      </Route>
    </Routes>
  );
};

RouteContainer.propTypes = {};
RouteContainer.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(RouteContainer);
