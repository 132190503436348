import * as React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ButtonInput from '../button';
import './questionTextBox.scss'

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#6b7374',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FAAA2F',
        },
    },
});


export default function QuestionTextBox(props) {

    const [textboxValue, setTextboxValue] = React.useState("")

    React.useEffect(() => {
        if (props.defaultValue && props.defaultValue[0] !== '-1') {
            setTextboxValue(props.defaultValue[0])
        }
    }, [])

    const onChangeTextbox = (e) => {
        if (props.type === 'number') {
            setTextboxValue(e.target.value)
        } else {
            setTextboxValue(e.target.value)
        }
    }

    const onSubmitAnswer = () => {
        if (props.valueChange && textboxValue && textboxValue !== '-1') {
            props.valueChange(props.questionIndex, textboxValue, props.displayOrder);
        }

        if (textboxValue === '' && props.defaultValue && props.defaultValue[0] !== '-1') {
            setTextboxValue(props.defaultValue[0])
        }
    }

    return (
        <div className='padding-top-10 qus-text-box'>
            <div style={{ width: '95%', paddingRight: '20px' }}>
                <CssTextField
                    multiline={props.type === 'number' ? false : true}
                    rowsMax={props.type === 'number' ? 1 : 4}
                    className='textbox-size'
                    type={props.type ? props.type : 'text'}
                    color={'error'}
                    onChange={onChangeTextbox}
                    placeholder={props.placeholder}
                    value={textboxValue}
                    sx={{
                        '& fieldset': { borderRadius: '10px', border: '1px solid #364242' },
                        '& textarea': { paddingLeft: (theme) => theme.spacing(1), color: '#fff', borderRadius: '10px', backgroundColor: '#041214' },
                        '& input': { paddingLeft: (theme) => theme.spacing(1), color: 'white', borderRadius: '10px', backgroundColor: '#041214' },
                    }}
                />
            </div>
            <div style={{ width: '5%' }}>
                <ButtonInput
                    class={`margin-0 ok-button${(props.defaultValue && props.defaultValue[0] !== '-1' && textboxValue !== props.defaultValue[0]) ? '--active' : '--deactive'}`}
                    disabled={!(props.defaultValue && props.defaultValue[0] !== '-1' && textboxValue !== props.defaultValue[0])}
                    text={props.buttonText}
                    onSubmit={() => onSubmitAnswer()} />
            </div>
        </div>
    );
}
