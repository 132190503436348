import React from "react";
import "./result.scss";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/action/user";
import ResultHeader from "../../component/result/resultHeader";
import ResultBody from "../../component/result/resultBody";
import Footer from "../../component/footer";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const Result = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
    localStorage.removeItem("review");
  }, []);

  return (
    <>
      <ResultHeader />
      <div>
        <ResultBody />
      </div>
      <Footer />
    </>
  );
};

Result.propTypes = {};
Result.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Result);
