import { MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import './textboxWithDropdown.scss';
import { makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiSvgIcon-root": {
            color: "white",
            paddingTop: '0px',
            paddingBottom: '0px'
        }
    },
}));

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#6b7374',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FAAA2F',
        },
    },
});

const TextboxWithDropdown = (props) => {
    const classes = useStyles();

    return (
        <CssTextField
            type={props.type ? props.type : 'text'}
            onChange={(e) => props.valueChange && props.valueChange(e.target.value)}
            placeholder={props.placeholder}
            value={props.value && props.value > 0 ? props.value : ''}
            InputProps={{
                endAdornment:
                    props.isLabel ?
                        <div className={`${classes.root} select-option`} style={{
                            height: '31px',
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            paddingRight: '5px'
                        }}> {props.labelText} </div > : <Select
                            sx={{
                                color: 'white',
                                '& fieldset': { borderRadius: '10px', border: 'none' },
                            }}
                            className={`${classes.root} select-option`}
                            variant="outlined"
                            style={{ width: '100px' }}
                            defaultValue={props.dropDownDefaultValue}
                            onChange={(e) => props.selectChange && props.selectChange(e.target.value)}
                        >
                            {
                                props.option.map((obj, index) => {
                                    return <MenuItem className={`menu-item ${obj.value === props.dropDownDefaultValue ? 'selected-item' : ''}`} value={obj.value} style={{ width: '133px', justifyContent: 'center' }}>{obj.text}</MenuItem>
                                })
                            }
                        </Select>

            }}
            sx={{
                '& input': { paddingLeft: (theme) => theme.spacing(1), color: 'white', borderRadius: '10px', backgroundColor: '#041214' },
                '& fieldset': { borderRadius: '10px', border: '1px solid #364242', },
            }}
        />
    );
};

TextboxWithDropdown.propTypes = {};
TextboxWithDropdown.defaultProps = {};

export default TextboxWithDropdown;
