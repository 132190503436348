import RouteContainer from './routeContainer/RouteContainer';
import { BrowserRouter } from "react-router-dom";
import './css/base.scss';

function App() {
  return (
    <div>
      <BrowserRouter>
        <RouteContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
