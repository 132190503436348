import * as React from "react";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./questionOption.scss";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 15,
  height: 15,
  zIndex: 1,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#faaa2f",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 15,
    height: 15,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#faaa2f",
  },
});

function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function QuestionOption(props) {
  const onChangeRadio = (e) => {
    if (props.valueChange) {
      props.valueChange(
        props.questionIndex,
        e.target.value,
        props.displayOrder
      );
    }
  };

  return (
    <div>
      <RadioGroup
        id={`radioGroup-${props.answerIndex}`}
        className="padding-top-10 cus-radio-parent"
        defaultChecked={props.defaultValue}
        onChange={onChangeRadio}
      >
        {props.option.map((obj, index) => {
          return (
            <FormControlLabel
              value={obj.ID}
              className={`white-color question-option font-transform`}
              control={
                <BpRadio
                  checked={props.defaultValue.toString() === obj.ID.toString()}
                />
              }
              label={obj.OptionText}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
}
