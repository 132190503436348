import React from 'react';
import './progress.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user';
import Header from '../../component/progress/header';
import ProgressBody from '../../component/progress/progressBody';

const Progress = (props) => {

    return (
        <>
            <Header />
            <ProgressBody />
        </>
    );
};

Progress.propTypes = {};
Progress.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Progress);
