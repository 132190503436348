import { Button } from '@mui/material';
import React from 'react';
import './button.scss';

const ButtonInput = (props) => {
    return (
        <Button
            variant="contained"
            className={`${props.class ? props.class : ''} btn`}
            onClick={() => props.onSubmit && props.onSubmit()}
            disabled={props.disabled ? props.disabled : false}
            startIcon={props.iCon ? <img alt="Back" className='prefix margin-left-10 vertical-align' src={props.iCon} /> : null}
        >
            {props.text}
        </Button>
    );
};

ButtonInput.propTypes = {};
ButtonInput.defaultProps = {};

export default ButtonInput;
