import { InputLabel } from '@mui/material';
import React from 'react';
import './labels.scss';

const LabelInput = (props) => {
    return (
        <InputLabel
            style={props.style}
            className={`${props.className ? props.className : ''} white-color`}
            required={props.requireField}>
            {props.text}
        </InputLabel>
    );
};

LabelInput.propTypes = {};
LabelInput.defaultProps = {};

export default LabelInput;
