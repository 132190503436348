import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import './snackBar.scss';

const SnackBar = (props) => {
    return (
        <>
            {props.handleCloseSnackBar && props.message &&
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} open={props.open} autoHideDuration={3000} onClose={() => props.handleCloseSnackBar()}>
                    <Alert onClose={() => props.handleCloseSnackBar()} severity="error" sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                </Snackbar>
            }
        </>
    );
};

SnackBar.propTypes = {};
SnackBar.defaultProps = {};

export default SnackBar;
