import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import './toggleButtonGroupBox.scss';

const ToggleButtonGroupBox = (props) => {
    const handleChange = (e) => {
        if (props.valueChange) {
            props.valueChange(e.target.value)
        }
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={props.value}
            exclusive
            className='toogle-button-group'
            onChange={handleChange}
        >
            {props.option.map((obj, index) => {
                return (
                    <ToggleButton
                        className={`toogle-button-group__single-group ${props.value === obj.value ? 'toogle-button-group__active-group' : ''}`}
                        value={obj.value}>
                        {obj.text}
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    );
};

ToggleButtonGroupBox.propTypes = {};
ToggleButtonGroupBox.defaultProps = {};

export default ToggleButtonGroupBox;
