import React, { useEffect, useState } from "react";
import "./header.scss";
import { connect } from "react-redux";
import headerImage from "../../../assets/images/header.png";
import LabelInput from "../../../atoms/label";
import ProgressBar from "../../../atoms/progressBar";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router";
import { getSurveyList } from "../../../redux/action/user";

const Header = (props) => {
  const [progress, setProgress] = useState(0);
  const [answredGroup, setAnsworGroup] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    props.getSurveyList();
  },[])

  useEffect(() => {
    if (props.user?.totalDetermine.length > 0) {
      const answedDetermin = props.user?.totalDetermine.filter(
        (obj) => obj.isFillUp === true
      ).length;
      if (props.user?.totalDetermine.length > answedDetermin) {
        const progrsss =
          ((answedDetermin + 1) / props.user?.totalDetermine.length) * 100;
        setAnsworGroup(answedDetermin + 1);
        setProgress(progrsss);
      } else {
        setAnsworGroup(props.user?.totalDetermine.length);
        setProgress(100);
      }
    }
  }, [props.user?.totalDetermine]);

  function signout() {
    localStorage.clear("userKey");
    localStorage.clear("_grecaptcha");
    sessionStorage.clear("_grecaptcha");
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate("/signin");
    alert(
      "Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment"
    );
    window.location.reload();
  }


  return (
    <div className="progress-header-section">
      <div className="progress-header-section__fixed">
        <div className="gridContainer">
          <div className="inner-progress-header__fixed">
            <div>
              <img src={headerImage} alt={headerImage}></img>
            </div>
            <div className="cus-label progress-header-section__right-side">
              <div>
                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
              </div>
              {props.user?.surveyIdList && props.user?.surveyIdList.length > 0 &&
                <div className="my-data-label">
                  <InputLabel
                      style={{ cursor: "pointer", paddingRight: "20px"}}
                      onClick={() => navigate("/history")}
                    >
                      My Data
                  </InputLabel>
                </div>
              }
              <div className="progress-header-section__right-side--logout">
                <InputLabel
                  style={{ cursor: "pointer", paddingRight: "30px"}}
                  onClick={() => signout()}
                >
                  Logout
                </InputLabel>
              </div>
              <div className="progress-header-section__right-side--userdetail">
                <div>Welcome</div>
                <div>{props.user.firstName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {localStorage.getItem("review") === null &&
        <div className="progress-header-section__body-header">
        <div className="gridContainer">
          <div className="inner-progress-header__fixed--bottom">
            <h3 className="inner-progress-header__fixed--bottom--left">
              <LabelInput
                text={props.user.language?.questionList?.title + " : "}
                className={"header-title"}
              />
              <LabelInput
                className={"header-title"}
                text={props.user?.activeGroup || ""}
              />
            </h3>
            <div className="inner-progress-header__fixed--bottom--right">
              {props.user?.questionList?.length > 0 && (
                <>
                  <div className="left-side-progress">
                    <ProgressBar progressValue={progress} />
                  </div>
                  <h4 style={{ display: "flex", marginLeft: "10px" }}>
                    <LabelInput
                      text={`${
                        answredGroup > 9 ? answredGroup : "0" + answredGroup
                      }`}
                    />
                  </h4>
                  <LabelInput
                    text={`/${
                      props.user?.totalDetermine?.length > 9
                        ? props.user?.totalDetermine?.length
                        : "0" + props.user?.totalDetermine?.length
                    } ${props.user.language?.questionList?.sections}`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
};

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {
  getSurveyList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Header);
