import React, { useEffect, useState } from "react";
import "./signupSection.scss";
import { connect } from "react-redux";
import { updateUserData, signUpUser } from "../../redux/action/user";
import TextBox from "../../atoms/textbox";
import TextBox2 from "../../atoms/textBox2";
import ButtonInput from "../../atoms/button";
import LabelInput from "../../atoms/label";
import CountryDropDown from "../../atoms/countryDropDown";
import ReCAPTCHA from "react-google-recaptcha";
import SnackBar from "../../atoms/snackBar";

import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";

const SignupSection = (props) => {
  const [activeButton, setActiveButton] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const styles = {
    div: {
      background: "transparent",
      color: " #fff",
      paddingTop: "20px",
      width: "100%",
    },
    input: {
      backgroundColor: "white",
      minHeight: "20px",
      minWidth: "20px",
      verticalAlign: "middle",
      position: "relative",
      bottom: "1px",
      float: "left",
      marginRight: "10px",
    },
    span: {
      fontFamily: "inherit",
      fontSize: "smaller",
    },
  };

  // function setCheckboxValue() {
  //   if(isChecked)
  //       return 'Yes';
  //   return 'No';
  // }

  const Checkbox = ({ value }) => {
    return (
      <div className="checkbox-wrapper" style={styles.div}>
        <value>
          <input
            type="checkbox"
            style={styles.input}
            // checked= {isChecked}
            // onChange= {()=> setIsChecked((prev)=> !prev)}
          />
          <span style={styles.span}>{value}</span>
        </value>
        {/* <p style={{display: "none"}}>{isChecked ? "Selected" : "Unchecked"}</p> */}
        <p style={{ display: "none" }}>{/* {setCheckboxValue()} */}</p>
      </div>
    );
  };

  const enableButtonChange = () => {
    const parsedPhone = parsePhoneNumber(
      props.user?.signupForm?.mobileNumber ?? ""
    );
    const phoneNumber = removeCountryCode({
      code: "+" + props?.user?.signupForm?.countryCode,
      phone: props.user.loginForm.mobileNumber,
    });

    if (
      props.user?.signupForm?.emailaddress &&
      props.user?.signupForm?.firstName &&
      props.user?.signupForm?.lastName &&
      props.user?.signupForm?.mobileNumber &&
      captchaValue
    ) {
      if (parsedPhone && phoneNumber.length > 0) {
        if (phoneNumber.length > 8) {
          setActiveButton(true);
        } else {
          setActiveButton(false);
        }
      } else {
        setActiveButton(true);
      }
    } else {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    enableButtonChange();
  }, [props.user, captchaValue]);

  const emailChange = (emailid) => {
    const value = emailid.toLowerCase();
    if (value.length > 50) return;

    props.updateUserData({
      ...props.user,
      signupForm: {
        ...props.user.signupForm,
        emailaddress: value,
      },
    });
  };

  const hostName = window.location.hostname;
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>", hostName)

  const hostNameChange = (value) => {
    props.updateUserData({
      ...props.user,
      signupForm: {
        ...props.user.signupForm,
        hostName: hostName,
      },
    });
  };

  // const marketingInfo= setCheckboxValue();
  // // console.log('>>>>>>>>opt-in for marketing', marketingInfo);

  // const marketingInfoChange = (value) => {
  //   props.updateUserData({
  //     ...props.user,
  //     signupForm: {
  //       ...props.user.signupForm,
  //       marketingInfo: marketingInfo
  //     },
  //   });
  // };

  const firstNameChange = (value) => {
    const finalValue = value.replace(/[^a-zA-Z]/g, "");
    if (finalValue.length > 20) return;

    props.updateUserData({
      ...props.user,
      signupForm: {
        ...props.user.signupForm,
        firstName: finalValue,
      },
    });
  };

  const lastNameChange = (value) => {
    const finalValue = value.replace(/[^a-zA-Z]/g, "");
    if (finalValue.length > 20) return;

    props.updateUserData({
      ...props.user,
      signupForm: {
        ...props.user.signupForm,
        lastName: finalValue,
      },
    });
  };

  useEffect(() => {
    if (props.user?.registrationError) {
      setErrorMessage(props.user?.registrationError);
      setTimeout(() => {
        props.updateUserData({
          ...props.user,
          registrationError: "",
        });
      }, 1500);
    }
  }, [props.user?.registrationError]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const redirectToPage = (page) => {
    if (props.changePage) props.changePage(page);
  };

  const validateData = () => {
    if (
      !props.user?.signupForm?.emailaddress.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/
      )
    ) {
      setErrorMessage("Enter valid email address");
      return false;
    } else if (!props.user?.signupForm?.mobileNumber) {
      setErrorMessage("Enter Mobile Number");
      return false;
    } else if (props.user?.signupForm?.mobileNumber.length < 6) {
      setErrorMessage("Enter Valid Mobile Number");
      return false;
    } else {
      return true;
    }
  };

  const mobileNoChange = (value) => {
    if (mobileError) {
      setMobileError(undefined);
    }
    if (value.length >= 0 && value.length <= 15) {
      props.updateUserData({
        ...props.user,
        signupForm: {
          ...props.user.signupForm,
          mobileNumber: value,
        },
      });
    }
  };

  const countryCodeChange = (value) => {
    props.updateUserData({
      ...props.user,
      signupForm: {
        ...props.user.signupForm,
        countryCode: value,
      },
    });
  };

  const gotoOTP = () => {
    // const phone = parsePhoneNumber(props.user.signupForm.mobileNumber);
    const phoneNumber = removeCountryCode({
      code: "+" + props?.user?.signupForm?.countryCode,
      phone: props?.user?.signupForm?.mobileNumber,
    });

    if (phoneNumber.length > 0 && phoneNumber.length !== 10) {
      setMobileError("Phone number must be of length 10");
      return;
    }

    if (validateData()) {
      props.signUpUser({
        firstName: props.user?.signupForm?.firstName,
        lastName: props.user?.signupForm?.lastName,
        emailaddress: props.user?.signupForm?.emailaddress,
        hostName: hostName,
        mobileNumber: phoneNumber,
        countryCode: "+" + props?.user?.signupForm?.countryCode,
        // marketingInfo: marketingInfo
      });

      /* props.updateUserData({
          ...props.user,
          status: 'otp',
      }) */
      redirectToPage("otp");
    }
  };
  const removeCountryCode = (data) => {
    // console.log(data);
    var newStr = data.phone.replace(data.code, "");
    return newStr;
  };

  const gotoLogin = () => {
    props.updateUserData({
      ...props.user,
      isNewUser: false,
    });

    redirectToPage("login");
  };

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
    setErrorMessage("");
  };

  return (
    <div className="login_form signup-form">
      <div className="container-fluid">
        <div className="row">
          <h2 className="white-color avenir-bold">
            {props.user.language?.signup?.title}
          </h2>
          {/* <p className='subTitle'> */}
          {/* {props.user.language?.signup?.subTitle} */}
          {/* </p> */}
          <h4 style={{ color: "#fff", display: "flex", gap: 5 }}>
            Email already registered please{" "}
            <div
              className="yellow-font"
              style={{ cursor: "pointer", width: "fit-content" }}
              onClick={() => gotoLogin()}
            >
              {props.user.language?.signup?.loginButton}
            </div>
          </h4>
        </div>
        <LabelInput
          text={props.user.language?.signup?.firstName?.title}
          requireField={props.user.language?.signup?.firstName?.isRequire}
        />
        <TextBox
          placeholder={props.user.language?.signup?.firstName?.placeHolder}
          value={props.user?.signupForm?.firstName}
          valueChange={firstNameChange}
          tooltipText={props.user.language?.signup?.firstName?.tooltip}
        />

        <LabelInput
          text={props.user.language?.signup?.lastName?.title}
          requireField={props.user.language?.signup?.lastName?.isRequire}
        />
        <TextBox
          placeholder={props.user.language?.signup?.lastName?.placeHolder}
          value={props.user?.signupForm?.lastName}
          valueChange={lastNameChange}
          tooltipText={props.user.language?.signup?.lastName?.tooltip}
        />

        <LabelInput
          text={props.user.language?.signup?.email?.title}
          requireField={props.user.language?.signup?.email?.isRequire}
        />
        <TextBox2
          placeholder={props.user.language?.signup?.email?.placeHolder}
          emailid={props.user?.signupForm?.emailaddress}
          valueChange={emailChange}
          tooltipText={props.user.language?.signup?.email?.tooltip}
        />

        <LabelInput
          text={props.user.language?.signup?.mobileNo?.title}
          requireField={props.user.language?.signup?.mobileNo?.isRequire}
        />
        <CountryDropDown
          dropDownDefaultValue={props.user?.signupForm?.countryCode}
          placeholder={props.user.language?.signup?.mobileNo?.placeHolder}
          type="number"
          value={props.user?.signupForm?.mobileNumber}
          valueChange={mobileNoChange}
          countryCodeChange={countryCodeChange}
          tooltipText={props.user.language?.signup?.mobileNo?.tooltip}
        />

        <div className="phone-with-error">
          <div className="PhoneInput">
            {/*<PhoneInputWithCountrySelect
              defaultCountry={"US"}
              value={props.user.loginForm.mobileNumber}
              // onCountryChange={(country) => {
              //   if (!country) return;
              //   countryCodeChange("+" + getCountryCallingCode(country));
              // }}
              onChange={(val) => {
                if (val) {
                  mobileNoChange(val);
                }
              }}
              className="cus-phone-input"
              placeholder="1234567890"
            />*/}
          </div>
          {mobileError && <span className="input-error">{mobileError}</span>}
        </div>

        <ReCAPTCHA
          sitekey="6Lf7GkQiAAAAAF-nHbYNl_mR1V0rYOA5KKOCRHvW"
          onChange={onChange}
        />

        <Checkbox value={props.user.language?.signup?.marketingInfo?.label} />

        <h5
          className="white-color avenir-reg terms-class"
          style={{ fontSize: "13px" }}
        >
          By clicking the button below, you agree to our &nbsp;
          <a
            className="yellow-font sign-up-welcome"
            href="https://japa.health/japa-health-terms-of-use/"
            target={"_blank"}
            rel="noreferrer"
            alt="Privacy Policy"
          >
            Terms of Use
          </a>{" "}
          &nbsp; and acknowledge that you have read our &nbsp;
          <a
            className="yellow-font sign-up-welcome"
            href="https://japa.health/japa-health-privacy-policy/"
            target="_blank"
            rel="noreferrer"
            alt="Privacy Policy"
          >
            Privacy Policy
          </a>
          .
        </h5>

        <div className="row">
          <ButtonInput
            disabled={!activeButton}
            class={`${
              activeButton ? "active-btn" : "deactive-btn"
            } margin-bottom-10`}
            text={props.user.language?.general?.getOtpButton}
            onSubmit={gotoOTP}
          />
        </div>
        <div className="row text-space-between font-size-14 benton-sans-font">
          <div style={{ width: "fit-content" }}>
            {/* <LabelInput
                            text={props.user.language?.signup?.loginButton}
                            className="yellow-font sign-up-welcome font-transform font-size-14 benton-sans-font" /> */}
          </div>
          <p className="white bottom-section">
            {props.user.language?.support?.text}
            <span
              className="yellow-font sign-up-welcome"
              style={{ marginLeft: "5px" }}
            >
              <a
                className="yellow-font sign-up-welcome"
                href={`mailto: ${props.user.language?.support?.email}`}
              >
                {props.user.language?.support?.email}
              </a>
            </span>
          </p>
        </div>
      </div>
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </div>
  );
};

SignupSection.propTypes = {};
SignupSection.defaultProps = {};

const actionCreators = {
  updateUserData,
  signUpUser,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(SignupSection);