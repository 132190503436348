import React, { useEffect, useState } from "react";
import "./progressBody.scss";
import { connect } from "react-redux";
import {
  getQuestionList,
  updateQuestionAnswer,
  updateUserData,
} from "../../../redux/action/user";
import QuestionList from "../questionList";
import { useNavigate } from "react-router";
import ButtonInput from "../../../atoms/button";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SnackBar from "../../../atoms/snackBar";
import undraw_well_done from "../../../assets/images/undraw_well_done.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

let datagetFirstTime = true;
let timeInvervel;
const ProgressBody = (props) => {
  const navigate = useNavigate();
  const [enableButton, setEnableButton] = useState(false);
  const [isSkipedButtonEnable, setIsSkipedButtonEnable] = useState(false);
  const [open, setOpen] = useState(false);
  const [getDatafirst, setGetDataFirst] = useState(true);

  const [showCongFleg, setShowCongfleg] = useState(-1);
  const [questionList, setQuestionList] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [questionIndex, setQuestionIndex] = useState(1);

  useEffect(() => {
    if (props.user?.questionError) {
      setErrorMessage(props.user?.questionError);
    }
  }, [props.user?.questionError]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    progressChange();
    if (document.getElementById(`questionIndex${questionIndex - 1}`)) {
      if (getDatafirst) {
        document
          .getElementById(`questionIndex${questionIndex - 1}`)
          .scrollIntoView({
            bottom: "80px",
          });
      } else {
        document
          .getElementById(`questionIndex${questionIndex - 1}`)
          .scrollIntoView({
            behavior: "smooth",
            bottom: "80px",
          });
      }
      setGetDataFirst(false);
    }

    if (questionIndex === questionList.length) {
      return;
    }

    const finalQuestion = questionList.find(
      (obj) => obj.DisplayOrder === questionIndex
    );
    if (finalQuestion) {
      changeSkipedButtton(
        finalQuestion.isMandatory === "1" || finalQuestion.isMandatory === ""
          ? false
          : true
      );
      if (props.user.activeGroup !== finalQuestion.Determinant) {
        setShowCongfleg(questionIndex);
        if (timeInvervel) {
          clearInterval(timeInvervel);
        }

        timeInvervel = setInterval(() => {
          setShowCongfleg(-1);
        }, 15000);
        props.updateUserData({
          activeGroup: finalQuestion.Determinant,
        });
      }
    }
  }, [questionIndex]);

  useEffect(() => {
    if (showCongFleg !== -1) {
      if (document.getElementById(`questionIndex${showCongFleg}`)) {
        document.getElementById(`questionIndex${showCongFleg}`).scrollIntoView({
          behavior: "smooth",
          bottom: "500px",
        });
      }
    }
  }, [showCongFleg]);

  const valueChange = (tempQuestionIndex, value, displayOrder) => {
    let tempArray = [...questionList];
    tempArray.map((mainObj) => {
      if (mainObj.userQuestionID === tempQuestionIndex) {
        if (mainObj.Response[0] === "") {
          setQuestionIndex(
            questionIndex + 1 < tempArray.length
              ? questionIndex + 1
              : tempArray.length
          );
          if (props.user.activeGroup !== mainObj.Determinant) {
            props.updateUserData({
              activeGroup: mainObj.Determinant,
            });
          }
        }

        let tempResponse = mainObj.Response.filter((obj) => obj !== "");
        if (mainObj.QuestionType === "Single") {
          tempResponse = [value];
        } else if (mainObj.QuestionType === "Multi") {
          tempResponse = value;
        } else {
          tempResponse = [value];
        }

        mainObj.Response = tempResponse;
        props.updateQuestionAnswer({
          questionId: mainObj.userQuestionID,
          userId: localStorage.getItem('userKey'),
          value: tempResponse,
          surveyId: localStorage.getItem('assesmentKey'),
        });
      }
      return "";
    });

    let isEnableButton = true;
    tempArray.map((obj) => {
      if (obj.Response[0] === "" && obj.isMandatory === "1") {
        isEnableButton = false;
      }
      return "";
    });
    setEnableButton(isEnableButton);

    setQuestionList(tempArray);
  };

  const progressChange = () => {
    const tempDetermin = [...props.user.totalDetermine];
    tempDetermin.map((obj) => {
      if (!obj.isFillUp) {
        const countAnswer = questionList.filter(
          (subObj) =>
            obj.name === subObj.Determinant &&
            subObj.isMandatory === "1" &&
            subObj.Response[0] === ""
        ).length;
        if (countAnswer === 0) {
          obj.isFillUp = true;
        }
      }
      return "";
    });

    if (tempDetermin.length > 0) {
      props.updateUserData({
        totalDetermine: tempDetermin,
      });
    }
  };

  useEffect(() => {
    props.getQuestionList();

    if (!props.user._id && !props.user.surveyId) {
      navigate("/");
    }

    if (
      localStorage.getItem("progressDone") !== null &&
      localStorage.getItem("detailDone") !== null
    ) {
      navigate(`/result`);
    }
  }, []);

  useEffect(() => {
    if (props.user?.questionList && props.user?.questionList?.length > 0) {
      setQuestionList(props.user?.questionList);
      if (props.user?.questionList.length > 0) {
        props.updateUserData({
          activeGroup: props.user?.questionList[0]?.determinantname,
        });
      }

      let isEnableButton = true;
      props.user?.questionList.map((obj) => {
        if (obj.Response[0] === "" && obj.isMandatory === "1") {
          isEnableButton = false;
        }
        return "";
      });
      setEnableButton(isEnableButton);

      if (datagetFirstTime) {
        let questionIndex = 1;
        let notAnswerd = true;

        const isMandatory = props.user?.questionList[0].isMandatory;
        changeSkipedButtton(
          isMandatory === "1" || isMandatory === "" ? false : true
        );
        props.user?.questionList.map((obj) => {
          if (notAnswerd) {
            if (obj.Response[0] !== "") {
              questionIndex = obj.DisplayOrder + 1;
              if (props.user.activeGroup !== obj.Determinant) {
                props.updateUserData({
                  activeGroup: obj.Determinant,
                });
              }
            }
            if (obj.Response[0] === "") {
              notAnswerd = false;
              if (props.user.activeGroup !== obj.Determinant) {
                props.updateUserData({
                  activeGroup: obj.Determinant,
                });
              }
            }
          }
          return "";
        });
        // console.log(questionIndex)
        setQuestionIndex(
          questionIndex < props.user?.questionList.length
            ? questionIndex
            : props.user?.questionList.length
        );
      }
    }
  }, [props.user.questionList]);

  const gotonextquestion = () => {
    setQuestionIndex(
      questionIndex < props.user?.questionList.length
        ? questionIndex
        : props.user?.questionList.length
    );
  };

  const onSubmitQuestion = () => {
    if(localStorage.getItem("review") !== null){
      navigate("/result");
    }
    setOpen(!open);
  };

  const modalSubmit = () => {
    localStorage.setItem("progressDone", true);
    navigate("/profiledetail");
  };

  const changeSkipedButtton = (value) => {
    setIsSkipedButtonEnable(value);
  };

  return (
    <>
      <div className="progrss-body">
        <div className="progrss-body__fixed">
          <QuestionList
            questionList={questionList}
            questionIndex={questionIndex}
            showCongFleg={showCongFleg}
            valueChange={valueChange}
            enableButton={enableButton}
          />
        </div>
      </div>
      <div className="progress-footer">
        <div className="gridContainer">
          <div className="progress-footer__fix-footer">
            <div>
              {isSkipedButtonEnable && (
                <ButtonInput
                  class={`progress-footer__button${
                    isSkipedButtonEnable ? "--active" : "--deactive"
                  }`}
                  text={props.user.language?.questionList?.skipButton}
                  disabled={!isSkipedButtonEnable}
                  onSubmit={() => gotonextquestion()}
                />
              )}
            </div>
            <div>
              <ButtonInput
                class={`progress-footer__button${
                  enableButton ? "--active" : "--deactive"
                }`}
                text={props.user.language?.questionList?.revealButton}
                disabled={!enableButton}
                onSubmit={() => onSubmitQuestion()}
              />
            </div>
          </div>
        </div>
        <Modal open={open} onClose={onSubmitQuestion}>
          <Box sx={style} className="success-modal">
            <Typography variant="h6" component="h2" className="center-text">
              {/* <img src={undraw_well_done} alt="" width={'224px'} height={'202px'} /> */}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className="center-text modal-title-text "
            >
              <b className="margin-top-20 avenir-bold">
                {props.user.language?.questionList?.modal?.title}
              </b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <p className="modalDetail benton-sans-font">
                {props.user.language?.questionList?.modal?.description}
              </p>
            </Typography>
            <ButtonInput
              class="modal-button"
              text={props.user.language?.questionList?.modal?.buttonText}
              onSubmit={() => modalSubmit()}
            />
          </Box>
        </Modal>
      </div>
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </>
  );
};

ProgressBody.propTypes = {};
ProgressBody.defaultProps = {};

const actionCreators = {
  getQuestionList,
  updateUserData,
  updateQuestionAnswer,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ProgressBody);