function GradientSVG() {
    const idCSS = "hello";
    const gradientTransform = `rotate(90)`;
    return (
        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    <stop offset="16.29%" stopColor="#f6a72e" />
                    <stop offset="85.56%" stopColor="#0bd7ee" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default GradientSVG;
